.container__html {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.container__html > div {
  display: grid;
  gap: 10px;
}

.container__html h1,
.container__html h2,
.container__html h3,
.container__html h4,
.container__html h5,
.container__html h6,
.container__html hr,
.container__html p {
  margin: 0;
}

.container__html__box {
  padding: 20px;
  color: var(--text-primary);
  border-radius: 0.25rem;
  box-shadow: var(--shadow-1);
  background-color: var(--white);
}

.container__html hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e0e0e0;
}
