.disabled {
  opacity: 0.5;
}

.disabled,
.disabled * {
  pointer-events: none;
}

.spacer {
  display: block;
  flex-grow: 1;
}

.loadingTopMargin {
  padding-top: 30px;
}

@media screen and (min-height: 600px) {
  .loadingTopMargin {
    padding-top: 15%;
  }
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centrendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.paragraph a {
  color: var(--color-primary);
}

.caption {
  white-space: break-spaces;
  font-size: 0.9rem;
  color: var(--color-grey-700);
  display: block;
  margin: 16px 0;
}

.caption + .caption {
  margin-top: 0;
}

.textCentred {
  text-align: center;
}

.link {
  color: var(--color-primary);
  text-decoration: none;
}

.link:hover {
  color: var(--color-secondary);
  text-decoration: underline;
}

[data-theme='dark'] .link,
[data-theme='dark'] .link:hover {
  color: var(--text-primary);
}

.label {
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  min-width: 90px;
  color: var(--color-grey-600);
  text-align: center;
  background-color: var(--color-grey-50);
  border: solid 1px var(--color-grey-500);
}

[data-theme='dark'] .label {
  background-color: var(--color-grey-600);
  color: var(--text-primary);
}

.label:global(.ok) {
  color: var(--color-success);
  border-color: var(--color-success);
}
[data-theme='dark'] .label:global(.ok) {
  color: var(--text-primary);
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.label:global(.warning) {
  color: var(--color-warning);
  border-color: var(--color-warning);
}

[data-theme='dark'] .label:global(.warning) {
  color: var(--text-primary);
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}

.label:global(.caution) {
  color: var(--color-error);
  border-color: var(--color-error);
}

[data-theme='dark'] .label:global(.caution) {
  color: var(--text-primary);
  background-color: var(--color-error);
  border-color: var(--color-error);
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 12px;
  width: 12px;
  cursor: pointer;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 12px;
}