.button {
  white-space: nowrap;
  color: var(--color-grey-700);
  border-color: transparent;
}

.button:not(:hover) {
  background-color: white;
}

.button svg {
  margin-right: 5px;
}
