.progressPlaceHolder {
  height: 4px;
}

.filters,
.reportActions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.filters {
  padding: 15px;
  padding-top: 10px;
  border-radius: 4px 4px 0 0;
  background-color: var(--color-grey-100);
}

[data-theme='dark'] .filters {
  background-color: var(--color-grey-700);
}

.reportActions {
  height: 40px;
  margin-bottom: 15px;
  transition: height 180ms ease-out, margin-botton 180ms ease-out;
}

.reportActions:empty {
  height: 0;
  margin-bottom: 0;
}

.reportActions .formControl {
  grid-column: 1 / 2;
}

.reportActions .downloadButton {
  grid-column: 2 / 2;
  box-shadow: var(--box-shadow-l);
}

.reportActions .formControl fieldset {
  border-color: transparent;
}

.filtersDivider {
  margin: 15px 0;
}

.tableRow:nth-child(odd) {
  background-color: var(--color-grey-200);
}

[data-theme='dark'] .tableRow:nth-child(odd) {
  background-color: var(--color-grey-700);
}

.tableRow td {
  border-bottom: none;
}

@media screen and (min-width: 600px) {
  .filters,
  .reportActions {
    grid-template-columns: repeat(3, 1fr);
  }

  .reportActions .formControl {
    grid-column: 1 / span 2;
  }

  .reportActions .downloadButton {
    grid-column: 3 / 3;
  }
}

@media screen and (min-width: 1024px) {
  .filters,
  .reportActions {
    grid-template-columns: repeat(4, 1fr);
  }
  .reportActions .downloadButton {
    grid-column: 4 / 4;
  }
}

.reportActions .select {
  background-color: white;
  box-shadow: var(--box-shadow-l);
  position: relative;
}

[data-theme='dark'] .reportActions .select {
  background-color: var(--background-paper);
}

.smart__filter__label::after {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: white;
}

.smart__filter__label__text {
  position: relative;
  z-index: 1;
}

[data-theme='dark'] .smart__filter__label::after {
  background-color: var(--background-paper);
}

.filters .formControl + .filters .formControl:nth-child(even) {
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .filters .formControl + .filters .formControl:nth-child(even) {
    margin-left: 10px;
  }
}

@media screen and (min-width: 600px) {
  .filters .selectLabel {
    max-width: 90%;
  }
}

.tableContainer {
  position: relative;
  background-color: var(--background-paper);
}

.tableContainer .loader {
  height: 3px;
}

.tableContainer th:global(.fixed),
.tableContainer td:global(.fixed) {
  white-space: nowrap;
}
