.container__StockLineChart {
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 10px;
  position: relative;
}

.StockLineChart {
  position: absolute;
}
