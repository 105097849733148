.dot_1 {
    animation: eV9A1LB78W53_to 1400ms linear infinite normal forwards;
}
@keyframes eV9A1LB78W53_to {
     0% {
        transform: translate(90px,250px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1);
    }
     7.142857% {
        transform: translate(90px,270px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     28.571429% {
        transform: translate(90px,197.226858px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     57.142857% {
        transform: translate(90px,250px)
    }
     100% {
        transform: translate(90px,250px)
    }
}
.dot_2 {
    animation: eV9A1LB78W55_to 1400ms linear infinite normal forwards
}
@keyframes eV9A1LB78W55_to {
     0% {
        transform: translate(170.535611px,250.000001px)
    }
     7.142857% {
        transform: translate(170.535611px,250.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     14.285714% {
        transform: translate(170.535611px,270.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     35.714286% {
        transform: translate(170.535611px,197.22686px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     64.285714% {
        transform: translate(170.535611px,250.000002px)
    }
     100% {
        transform: translate(170.535611px,250.000002px)
    }
}
.dot_3 {
    animation: eV9A1LB78W57_to 1400ms linear infinite normal forwards
}
@keyframes eV9A1LB78W57_to {
     0% {
        transform: translate(230px,250.000001px)
    }
     18.571429% {
        transform: translate(230px,250.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     25.714286% {
        transform: translate(230px,270.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     47.142857% {
        transform: translate(230px,197.22686px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     71.428571% {
        transform: translate(230px,250px)
    }
     100% {
        transform: translate(230px,250px)
    }
}
.dot_4 {
    animation: eV9A1LB78W59_to 1400ms linear infinite normal forwards
}
@keyframes eV9A1LB78W59_to {
     0% {
        transform: translate(230px,250.000001px)
    }
     26.428571% {
        transform: translate(230px,250.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     33.571429% {
        transform: translate(230px,270.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     55% {
        transform: translate(230px,197.22686px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     78.571429% {
        transform: translate(230px,250px)
    }
     100% {
        transform: translate(230px,250px)
    }
}
.dot_5 {
    animation: eV9A1LB78W511_to 1400ms linear infinite normal forwards
}
@keyframes eV9A1LB78W511_to {
     0% {
        transform: translate(230px,250.000001px)
    }
     32.857143% {
        transform: translate(230px,250.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     40% {
        transform: translate(230px,270.000001px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     61.428571% {
        transform: translate(230px,197.22686px);
        animation-timing-function: cubic-bezier(0.42,0,0.425,1)
    }
     85.714286% {
        transform: translate(230px,250px)
    }
     100% {
        transform: translate(230px,250px)
    }
}
