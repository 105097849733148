.title {
  font-weight: 400;
  text-align: center;
  margin: 0 0 20px;
}

@media screen and (max-width: 400px) {
  .container div:global(.recharts-legend-wrapper) {
    display: none;
  }
}
