.header {
  margin: 0;
  margin-bottom: 16px;
}

.title {
  line-height: 1.2em;
}

.container_report {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
}
