.paper {
  height: 100%;
  min-height: 256px;
  position: relative;
}

.reponsiveContainer {
  width: calc(100% - 20px) !important;
  height: calc(100% - 40px) !important;
  position: absolute;
}
